<template>
  <div
    class="c-container"
    @mousemove="hasActivatedDropdown = true"
  >
    <HeaderDropdown
      ref="dropdown"
      @touchstart.native="triggerHref = 'javascript:;'"
      @mouseleave-content="initCurrentBlogId"
      @touch-trigger="hasActivatedDropdown = true"
    >
      <!-- // NOTE: NuxtLinkだとhrefに 「/javascript:;」 と設定されてしまうのでaタグに変更 -->
      <a
        :href="triggerHref"
        class="c-trigger"
        slot="trigger"
      >
        {{ $t('header.searchMenuBlog') }}
        <CoconalaIcon
          name="chevron-down"
          color="ds2Gray600"
          size="8px"
        />
      </a>
      <ClientOnly>
        <div
          v-if="!hasMountedDropdown"
          class="c-loading"
        >
          <DLoading
            :is-full-page="false"
            active
          />
        </div>
        <SearchMenuBlogLv1
          v-if="hasActivatedDropdown"
          :blog-categories="blogCategories"
          @mounted="hasMountedDropdown = true"
        />
      </ClientOnly>
    </HeaderDropdown>
  </div>
</template>
<script>
import DLoading from '~/components/atoms/DS2/DLoading'
import HeaderDropdown from './HeaderDropdown'

export default {
  name: 'SearchMenuBlog',
  components: {
    DLoading,
    HeaderDropdown,
    SearchMenuBlogLv1: () => import('./SearchMenuBlogLv1')
  },
  props: {
    blogCategories: {
      type: Array,
      default() {
        return []
      }
    },
    initialBlogId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentBlogId: this.initialBlogId,
      hasActivatedDropdown: false,
      hasMountedDropdown: false,
      $dropdownContent: null,
      triggerHref: '/blogs/'
    }
  },
  methods: {
    initCurrentBlogId() {
      this.currentBlogId = null
    }
  }
}
</script>
<style lang="scss" scoped>
$submenu-item-width: 250px;
::v-deep .dropdown {
  &-content {
    display: flex;
    overflow-y: auto;
  }
}
.c-trigger {
  @include link-effect-background(4px);

  display: inline-block;
  color: $ds2-color-gray-900;
  letter-spacing: 0.02em;
}
.c-loading {
  min-height: 120px;
  width: 100%;
  background-color: $ds2-color-white;
}
</style>
